import React from "react";
import "../css/default.scss";
import "../css/introduction.scss";


const date1 = new Date(2001, 4, 16);
const date2 = new Date();
const age = date2.getFullYear() - date1.getFullYear();

const Introduction: React.FC<{show: boolean}> = ({show}) => {
  return (
    <div id="introduction" className="section padded-section" style={{display: show ? "block" : "none"}}>
      <div id="page-content">
        <span>
          <div id="home-page-upper-grid">
            <div id="summary">
              <h3 className="intro-title">Hello!</h3>
                <p>
                I'm <b>Mark Keeble</b>, a {age}-year-old <a target='_blank' rel="noreferrer" href='https://www.bcit.ca/study/programs/5500dipma'>BCIT CST</a> graduate 
                and <a target='_blank' rel="noreferrer" href='https://www.bcit.ca/programs/bachelor-of-science-in-applied-computer-science/'>BCIT BTECH/Bsc</a> student. 
                I'm an aspiring developer with experience in and passion for game development, web development, and software development. 
                </p>
                <p>
                My heart mostly lies in game development. Nowadays, my favourite is definetely using <b>C#</b> with <b>Unity</b> for my games. 
                However, I do also have experience using <b>C++</b> both with <b>Unreal Engine</b> and in a custom engine made for a school project as well{" "} 
                <a target='_blank' rel="noreferrer" href='https://github.com/metalpipemomo/bobo'>(Bobo)</a>.
                </p>
                <p>
                I've been making games since I was 15. I started out with <b>GameMaker Studio</b> and used that engine for around 2 years. I eventually 
                had to switch over to a proprietary engine that was being used for one of my high school courses which had many similarities with Unity <b>(Zero Engine)</b>. 
                </p>
              <p>
                I also <b>produce music</b> as a hobby! I enjoy creating both <b>soundtrack-type collections</b>{" "}
                as well some Trap, Lo-fi, Ambient, and whatever else comes to mind in the moment!.
              </p>
              <p>
                <b>There are examples of both my games and my music on this site!</b>
              </p>
              <p>
                <span>
                  <a href="/files/Mark_Keeble_Resume.pdf" download>
                    Here
                  </a>
                  {" "}is a copy of my Resume.
                </span>
                </p>
              <p>
                Send me a message at{" "}
                <span>
                  <a href="mailto: markkeeble14@gmail.com" type="email">
                    markkeeble14@gmail.com
                  </a>
                </span>
                {" "}with any inquiries about projects or potential work!
              </p>
            </div>
            <div id="skills-container">
              <div id="skills">
                <h3 className="intro-title">Skills</h3>

                <div id="skills-grid">
                  <div>
                    <h2>Languages</h2>
                    <p>C#</p>
                    <p>C++</p>
                    <p>Java</p>
                    <p>Javascript</p>
                    <p>Typescript</p>
                    <p>Python</p>
                    <p>HTML5</p>
                    <p>CSS3</p>
                    <p>Elixir</p>
                    <p>Go</p>
                    <p>C</p>
                    <p>SQL</p>
                  </div>
                  <div>
                    <h2>Engines/Frameworks</h2>
                    <p>Unity</p>
                    <p>Unreal Engine</p>
                    <p>Gamemaker Studio</p>
                    <p>React</p>
                    <p>React Native</p>
                    <p>Node.js</p>
                    <p>Firebase</p>
                  </div>
                  <div>
                    <h2>Tools</h2>
                    <p>git</p>
                    <p>GitHub</p>
                    <p>Ticket-tracking Applications</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export default Introduction;
