import React from "react";
import "../css/default.scss";
import "../css/loadingText.scss"

const LoadingText: React.FC<{ doneLoading: boolean, success: boolean, subject: string }> = ({ doneLoading, success, subject }) => {
	return (
        <section className={doneLoading && success ? "loading-text-container loading-text-container-inactive" 
        : "loading-text-container loading-text-container-active"}>
            <div className={!doneLoading ? "loading loading-text-active" : "loading loading-text-inactive"}>
                <span>L</span>
                <span>o</span>
                <span>a</span>
                <span>d</span>
                <span>i</span>
                <span>n</span>
                <span>g</span>
                <span>&nbsp;</span>
                {
                    [...subject].map((c, index) => {
                        
                            if (c == ' ')
                            {
                                return (<span key={index}>&nbsp;</span>)
                            } else {
                                return (<span key={index}>{c}</span>)
                            }
                        
                    })
                }
            </div>
            <div className={doneLoading && !success ? "loading loading-text-active" : "loading loading-text-inactive"}>
                <p>Couldn't Load {subject}</p>
            </div>
        </section>
	);
};

export default LoadingText;
