import React from "react";
import "../css/default.scss";
import "../css/games.scss";

import GameData from "../types/GameData";

// Images
import unity from "../images/unity-256.png";
import unreal from "../images/unreal-256.png";
import gamemaker from "../images/gamemaker-256.png";
import zero from "../images/zero-256.png";
import desktop from "../images/desktop-256.png";
import mobile from "../images/mobile-256.png";

const GameEntry: React.FC<{ data: GameData }> = ({ data }) => {
  const GetEngineLogo = (engine : string) => {
    switch (engine) {
      case "Unity":
        return unity;
      case "GameMaker":
        return gamemaker;
      case "Zero Engine":
        return zero;
      case "Unreal":
        return unreal;
    }
  }

  const GetPlatformIcon = (platform : string) => {
    switch (platform) {
      case "Desktop":
        return desktop;
      case "Mobile":
        return mobile;
    }
  }

  return (
    <a
      className="game-entry grow"
      style={{
        backgroundImage: "url(" + data.cover_url + ")",
      }}
      href={data.url}
      target="_blank"
      rel="noreferrer"
    >
      <img className="game-engine" 
          src={GetEngineLogo(data.engine)} 
          alt="engine-logo"
      />
      <img className="platform"
          src={GetPlatformIcon(data.platform)}
          alt="made-for-platform"
      />
    </a>
  );
};

export default GameEntry;
