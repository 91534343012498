import "./css/fonts.css";
import "./css/default.scss";

import SoundCloud from "soundcloud-scraper";

import defaultApi from "./defaultApi";

import Header from "./components/header";
import Introduction from "./components/introduction";
import Games from "./components/games";
import Music from "./components/music";
import Links from "./components/links";
import GameData from "./types/GameData";
import { useEffect, useState } from "react";
import Routes from "./routes";
import { Analytics } from "@vercel/analytics/react"

import { getMostRecentSongs, getFeaturedSongs, getMyGames } from "./apiEndpoints";
import Repositories from "./components/repositories";

const headers = {
	"Content-Type": "application/json",
};

function App() {
	const [gamesData, setGamesData] = useState<GameData[]>([]);
	const [hasFetchedGames, setHasFetchedGames] = useState(false);
	const [gamesError, setGamesError] = useState(false);

	const [recentMusicData, setMusicData] = useState<SoundCloud.SongData[]>([]);
	const [hasFetchedRecentMusic, setHasFetchedMusic] = useState(false);
	const [recentMusicError, setMusicError] = useState(false);

	const [featuredMusicData, setFeaturedMusicData] = useState<SoundCloud.SongData[]>([]);
	const [hasFetchedFeaturedMusic, setHasFetchedFeaturedMusic] = useState(false);
	const [featuredMusicError, setFeaturedMusicError] = useState(false);

	const [openPage, setOpenPage] = useState(Routes.Default);

	useEffect(() => {
		let arr = window.location.href.split('/');
		let path = arr[arr.length - 1].toLowerCase();
		if (path === "" || (path !== Routes.Games && path !== Routes.Music && path !== Routes.Repos)) {
			loadPage(Routes.Default);
		} else {
			loadPage(path);
		}
	}, []);

	useEffect(() => {
		try {
			const getGamesData = async () => {
				await defaultApi
					.get(getMyGames, {
						headers: headers,
					})
					.then((response) => {
						setGamesData(response.data);
						setHasFetchedGames(true);
					})
					.catch((error) => {
						console.log(error);
						setGamesError(true);
						setHasFetchedGames(true);
					});
			};
			getGamesData();
		} catch (error) {
			console.log(error);
			setGamesError(true);
			setHasFetchedGames(true);
		}
	}, []);

	useEffect(() => {
		try {
			const getRecentMusicsData = async () => {
				await defaultApi
					.get(getMostRecentSongs, {
						headers: headers,
					})
					.then((response) => {
						setMusicData(response.data);
						setHasFetchedMusic(true);
					})
					.catch((error) => {
						console.log(error);
						setMusicError(true);
						setHasFetchedMusic(true);
					});
			};
			getRecentMusicsData();
		} catch (error) {
			console.log(error);
			setMusicError(true);
			setHasFetchedMusic(true);
		}
	}, []);

	useEffect(() => {
		try {
			const getFeaturedMusicsData = async () => {
				await defaultApi
					.get(getFeaturedSongs, {
						headers: headers,
					})
					.then((response) => {
						setFeaturedMusicData(response.data);
						setHasFetchedFeaturedMusic(true);
					})
					.catch((error) => {
						console.log(error);
						setFeaturedMusicError(true);
						setHasFetchedFeaturedMusic(true);
					});
			};
			getFeaturedMusicsData();
		} catch (error) {
			console.log(error);
			setFeaturedMusicError(true);
			setHasFetchedFeaturedMusic(true);
		}
	}, []);

	const handleButtonClick = (page: string) => {
		loadPage(page);
	}

	const loadPage = (page: string) => {
		window.history.pushState({}, "", page);
		setOpenPage(page);
	}

	return (
		<div className="App">
			<Analytics />

			<Header onPress={handleButtonClick}></Header>

			<Introduction show={openPage === Routes.Intro}></Introduction>

			<Games show={openPage === Routes.Games} data={gamesData} fetchedData={hasFetchedGames} errorOccured={gamesError}></Games>

			<Music show={openPage === Routes.Music} 
				recentData={recentMusicData} 
				fetchedRecentData={hasFetchedRecentMusic} 
				recentDataErrorOccured={recentMusicError} 
				featuredData={featuredMusicData} 
				fetchedFeaturedData={hasFetchedFeaturedMusic}
				featuredDataErrorOccured={featuredMusicError}></Music>

			<Repositories show={openPage === Routes.Repos}></Repositories>
			
			<Links></Links>
			{
        /*
				  <Software></Software>
				  <Webdev></Webdev>
				  <Blog></Blog>
      	*/}
    </div>
	);
}

export default App;
