import React, { useEffect } from "react";
import "../css/default.scss";
import "../css/links.scss";

// Links
import res from "../images/resume.png";
import gh from "../images/github.png";
import li from "../images/li.png";
import twit from "../images/twit.png";
import itch from "../images/itch.png";
import sc from "../images/sc.png";
import bc from "../images/bandcamp.png";

const Links = () => {
  const introduceLinks = () => {
    const elements: HTMLElement[] = Array.from(document.getElementsByClassName("link-button") as HTMLCollectionOf<HTMLElement>);
    const delayInc = .25;
    let delay = 0;
    elements.forEach(element => {
      element.classList.remove("link-inactive");
      element.classList.add("link-active");
      element.style.transitionDelay = delay + "s";
      delay += delayInc;
    });
  };

  useEffect(() => {
    introduceLinks();
  }, []);

  return (
    <div id="links">
      <a
        id="resume-button"
        href="/files/Mark_Keeble_Resume.pdf"
        target="_blank"
        rel="noreferrer"
        className="link-button link-inactive"
      >
        <img src={res} alt="Resume" />
      </a>
      <a
        id="github-button"
        href="https://github.com/MarkKeeble14"
        target="_blank"
        rel="noreferrer"
        className="link-button link-inactive"
      >
        <img src={gh} alt="GitHub" />
      </a>
      <a
        id="linkedin-button"
        href="https://www.linkedin.com/in/markkeeble/"
        target="_blank"
        rel="noreferrer"
        className="link-button link-inactive"
      >
        <img src={li} alt="LinkedIn" />
      </a>
      <a 
      id="twitter-button" 
      href="https://twitter.com/Oribeau14" 
      target="_blank"
      rel="noreferrer"
      className="link-button link-inactive"
      >
        <img src={twit} alt="Twitter" />
      </a>

      <a 
      id="itch-button" 
      href="https://mark-keeble.itch.io/" 
      target="_blank"
      rel="noreferrer"
      className="link-button link-inactive"
      >
        <img src={itch} alt="itch.io" />
      </a>
      <a
        id="soundcloud-button"
        href="https://soundcloud.com/markkeeble"
        target="_blank"
        rel="noreferrer"
        className="link-button link-inactive"
      >
        <img src={sc} alt="SoundCloud" />
      </a>
      <a
        id="bandcamp-button"
        href="https://markkeeble.bandcamp.com/"
        target="_blank"
        rel="noreferrer"
        className="link-button link-inactive"
      >
        <img src={bc} alt="Bandcamp" />
      </a>
    </div>
  );
};

export default Links;
