import React, {useEffect} from "react";
import "../css/default.scss";
import "../css/header.scss";
import Routes from "../routes";

type Props = {
	onPress: any
};

const Header: React.FC<Props> = ({onPress}) => {
	const SetSectionPadding = () => {
		const sections = document.getElementsByClassName("section") as HTMLCollectionOf<HTMLElement>;
		const headerHeight = document.getElementById("header")?.clientHeight;
		if (headerHeight === undefined)
			return;
		for (let i = 0; i < sections.length; i++) {
			const section: HTMLElement = sections[i];
			section.style.paddingTop = headerHeight?.toString() + "px";
		}
	}

	useEffect(() => {
		SetSectionPadding();
		window.addEventListener('resize', SetSectionPadding);
	}, []);

	return (
		<div id="header">
			<div id="navigation">
				<button className="nav-button nav-blue" onClick={() => onPress(Routes.Intro)}>
					<div>
						<p>
							Intro
						</p>
					</div>
				</button>
				<button className="nav-button nav-red" onClick={() => onPress(Routes.Games)}>
					<div>
						<p>
							Games
						</p>
					</div>
				</button>
				<button className="nav-button nav-purple" onClick={() => onPress(Routes.Music)}>
					<div>
						<p>
							Music
						</p>
					</div>
				</button>
				<button className="nav-button nav-pink" onClick={() => onPress(Routes.Repos)}>
					<div>
						<p>
							Repos
						</p>
					</div>
				</button>
			</div>
		</div>
	);
};

export default Header;
