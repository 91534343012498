import React from "react";
import "../css/default.scss";
import "../css/music.scss";

import SoundCloud from "soundcloud-scraper";
import MusicEntry from "./musicEntry";
import LoadingText from "./loadingText";

const Music: React.FC<{
	show: boolean,
	recentData: SoundCloud.SongData[];
	fetchedRecentData: boolean;
	recentDataErrorOccured: boolean;
	featuredData: SoundCloud.SongData[];
	fetchedFeaturedData: boolean;
	featuredDataErrorOccured: boolean;
}> = ({ show, recentData, fetchedRecentData, recentDataErrorOccured, featuredData, fetchedFeaturedData, featuredDataErrorOccured }) => {
	return (
		<div
			id="music"
			className="section"
			style={{display: show ? "block" : "none"}}
		>
			<p className="account-for-lower-embeds">Featured</p>
			<LoadingText
				doneLoading={fetchedFeaturedData}
				success={!featuredDataErrorOccured}
				subject={"Featured Music"}
			></LoadingText>
			<div id="featured-music-list" className="sc-music-list">
				{featuredData.map((element, index) => {
					if (element === undefined)
					{
						return (null);
					} else 
					{
						return <MusicEntry key={index} data={element}></MusicEntry>; 
					}
				})}
			</div>

			<p className="account-for-upper-embeds">Uploaded Recently</p>
			<LoadingText
				doneLoading={fetchedRecentData}
				success={!recentDataErrorOccured}
				subject={"Recent Music"}
			></LoadingText>
			<div className="sc-music-list">
				{recentData.map((element, index) => {
					if (element === undefined)
					{
						return (null);
					} else 
					{
						return <MusicEntry key={index} data={element}></MusicEntry>; 
					}
				})}
			</div>

			<div id="archives-section" className="padded-section">
				<p className="account-for-lower-embeds">Made With Acapellas</p>
				<div id="acapellas-bandcamp-embeds" className="bandcamp-embeds">
					<div>
						<iframe 
							title="Sayonara"
							src="https://bandcamp.com/EmbeddedPlayer/track=114492022/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" 
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/track/sayonara-aries-my-remix">
								Sayonara (Aries) - My Remix by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe 
							title="Conversations"
							src="https://bandcamp.com/EmbeddedPlayer/track=3030800457/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/track/conversations-aries-my-remix">
								Conversations (Aries) - My Remix by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe
							title="Wait-Up"
							src="https://bandcamp.com/EmbeddedPlayer/track=3722732221/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/track/wait-up-sean-kingston-my-remix">
								Wait Up (Sean Kingston) - My Remix by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe 
							title="Love"
							src="https://bandcamp.com/EmbeddedPlayer/track=3343746530/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/track/love-keyshia-cole-my-remix">
								Love (Keyshia Cole) - My Remix by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe
							title="Carrollton"
							src="https://bandcamp.com/EmbeddedPlayer/track=3789797000/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/track/carrollton-uicideboy-my-remix">
								Carrollton ($uicideboy$) - My Remix by Mark Keeble
							</a>
						</iframe>
					</div>
				</div>

				<p>Archives</p>
				<div id="archives-bandcamp-embeds" className="bandcamp-embeds">
					<div>
						<iframe
							title="Currency"
							src="https://bandcamp.com/EmbeddedPlayer/album=74108042/size=large/bgcol=333333/linkcol=0687f5/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/currency">
								Currency by Mark Keeble
							</a>
						</iframe>
					</div>
					
					<div>
						<iframe
							title="my-favourite-era"
							src="https://bandcamp.com/EmbeddedPlayer/album=4218767895/size=large/bgcol=333333/linkcol=0687f5/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/my-favourite-era">
								my favourite era by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe
							title="lowkey"
							src="https://bandcamp.com/EmbeddedPlayer/album=1422328713/size=large/bgcol=333333/linkcol=0687f5/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/lowkey">
								lowkey by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe
							title="Mist"
							src="https://bandcamp.com/EmbeddedPlayer/album=986381504/size=large/bgcol=333333/linkcol=0f91ff/artwork=small/transparent=true/" 
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/mist">
								Mist by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe 
							title="dump"
							src="https://bandcamp.com/EmbeddedPlayer/album=1015631243/size=large/bgcol=333333/linkcol=0f91ff/artwork=small/transparent=true/" 
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/dump">
								dump by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe
							title="lo-fidelity"
							src="https://bandcamp.com/EmbeddedPlayer/album=2087851202/size=large/bgcol=333333/linkcol=0687f5/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/lo-fidelity">
								lo fidelity by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe 
							title="Forbidden"
							src="https://bandcamp.com/EmbeddedPlayer/album=2497356960/size=large/bgcol=333333/linkcol=0687f5/artwork=small/transparent=true/" 
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/forbidden-inscryption-soundtrack-type-beats">
								Forbidden (Inscryption Soundtrack Type Beats) by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe
							title="observatory"
							src="https://bandcamp.com/EmbeddedPlayer/album=2414090262/size=large/bgcol=333333/linkcol=0687f5/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/observatory">
								Observatory by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe
							title="min-max"
							src="https://bandcamp.com/EmbeddedPlayer/album=946100524/size=large/bgcol=333333/linkcol=0687f5/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/min-max">
								min / max by Mark Keeble
							</a>
						</iframe>
					</div>

					<div>
						<iframe
							title="strange-things-are-happening"
							src="https://bandcamp.com/EmbeddedPlayer/album=935548162/size=large/bgcol=333333/linkcol=0687f5/artwork=small/transparent=true/"
							seamless
						>
							<a href="https://markkeeble.bandcamp.com/album/strange-things-are-happening">
								strange things are happening by Mark Keeble
							</a>
						</iframe>
					</div>
				</div>

				<p><a href="https://markkeeble.bandcamp.com/" target="_blank">More to see on my Bandcamp!</a></p>
			</div>
		</div>
	);
};

export default Music;
