import React from "react";
import "../css/default.scss";
import "../css/music.scss";

import SoundCloud from "soundcloud-scraper";

const MusicEntry: React.FC<{ data: SoundCloud.SongData }> = ({ data }) => {
  return (
    <a
    className="music-entry"
      href={data.url}
      rel="noreferrer"
    >
      <iframe width="100%" scrolling="no" allow="autoplay" 
        src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + data.id + 
        "&color=%235c5c5c" + 
        "&auto_play=false" + 
        "&hide_related=false" + 
        "&show_comments=true" + 
        "&show_user=true" + 
        "&show_reposts=false" + 
        "&show_teaser=true" + 
        "&visual=true"}
        title={data.title}
        >
        </iframe>
        <div className="music-entry-title">
          <a href="https://soundcloud.com/markkeeble" title="mark keeble" target="_blank" rel="noreferrer">mark keeble</a> 
          <a href="https://soundcloud.com/markkeeble/blowback" title={data.title} target="_blank" rel="noreferrer">{data.title}</a>
        </div>
    </a>
  );
};

export default MusicEntry;
