import "../css/default.scss";
import "../css/repositories.scss";

import { RepoCardFetch } from "react-repo-widget"; // Fetch data from the GitHub

import "react-repo-widget/dist-esm/styles.css";

let repo = {
    name: "",
    owner: {
        avatarUrl: "",
        login: "",
    },
    description: "",
    forks: {
        totalCount: 0,
    },
    primaryLanguage: {
        name: "",
    },
    licenseInfo: {
        spdxId: "",
    },
    pushedAt: "",
    stargazerCount: 0,
    watchers: {
        totalCount: 0,
    }
}
const myUsername : string = "MarkKeeble14";
const myRepoNames : string[] = [ "Lolesports-TournamentGen", "3D-Chess", "The-Game-Of-Life" ];

const moUsername : string = "metalpipemomo";
const moRepoNames : string[] = [ "bobo" ];

const Repositories: React.FC<{show: boolean}> = ({show}) => {
	return (
		<div id="repositories" className="section padded-section" style={{display: show ? "block" : "none"}}>
			<div id="repositories-list-container">
                {myRepoNames.map((element, index) => {
                    return (
                        <div className="repositories-list-item">
                            <RepoCardFetch login={myUsername} reponame={element} repo={repo}/>
                        </div>
                    )
                })}
                {moRepoNames.map((element, index) => {
                    return (
                        <div className="repositories-list-item">
                            <RepoCardFetch login={moUsername} reponame={element} repo={repo}/>
                        </div>
                    )
                })}
			</div>
		</div>
	);
};

export default Repositories;
